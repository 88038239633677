export const constants = {
  SAMPLE_INVENTORY_SHEET: "Inventory Template and Example Sheets (Restaurants and Bars).xlsx",
  HUBSPOT_EMAIL_CONFIRMATION_FORMID: "5ff7a312-1023-4ada-9d3c-99bff8ae06c9",
  HUBSPOT_EMAIL_VERIFICATION_FORMID: "492520b8-2ab0-4ab6-aad4-ce9c1ced9ddc",
  HUBSPOT_USER_REGISTER_FORMID: "2e4469f6-3b06-4207-bcc6-57babfc148b1",
  HUBSPOT_RESTAURANT_CREATE_FORMID: "3057a1b7-7162-4095-9580-3e2de7483b0f",
  FEATURED: "featured",
  SCOTT_ACCOUNT_EMAIL: "brandtester5@getkexy.com",
  KEXY_CALENDLY_URL: "https://calendly.com/kexy/30min?back=1&month=2025-02",
  SLACK_INTEGRATION_URL: "https://slack-app.getkexy.com/slack/install/workspace/",
  EMPLOYEE: "Employee",
  PRO: "Pro",
  FREE: "Free",
  MONTH: "month",
  YEAR: "year",
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
  ANNUAL: "Annual",
  ENTERPRISE: "Enterprise",
  PAYMENT_STATUS: "subscription_payment_status",
  INVENTORY: "inventory",
  ORDERS: "orders",
  ORDER: "order",
  RESTAURANT: "restaurant",
  WEBPORTAL: "webportal",
  SUPPLIER: "supplier",
  BRAND: "brand",
  DISTRIBUTOR: "distributor",
  ADMIN: "admin",
  REP: "rep",
  IMPRESSION: "impression",
  IMPRESSION_DAY: "impression_day",
  SUBSCRIPTION_MONTH: "subscription_month",
  SUBSCRIPTION_YEAR: "subscription_year",
  SUBSCRIPTION_LIFETIME: "subscription_lifetime",
  SUBSCRIPTION: "subscription",
  SUBSCRIPTION_TYPE: "subscription_type",
  ACCOUNT_TYPE: "account_type",
  INVITED_BY: "invited_by",
  RECCURING_TYPE: "recurring_type",
  ACTIVE: "active",
  INACTIVE: "inactive",
  PUBLISHED: "published",
  SUCCESS: "success",
  DELETED: "deleted",
  PAUSE: "pause",
  COMPLETE: "complete",
  FINALIZED: "finalized",
  CREATED: "created",
  PENDING: "pending",
  CONFIRMED: "confirmed",
  RECEIVED: "received",
  ASENDING: "ASC",
  DESENDING: "DESC",
  CREATED_AT: "created_at",
  STRIPE_ID: "advertiserStripeID",
  POPULAR_DISTRIBUTOR: "POPULAR_DISTRIBUTOR",
  TEST_POPULAR_DISTRIBUTOR: "TEST_POPULAR_DISTRIBUTOR",
  FREE_DISTRIBUTOR: "FREE_DISTRIBUTOR",
  FREE_ACCOUNT_ESTABLISHMENT_LIMIT: 15,
  DESKTOP_ALLOWED_WIDTH: 992,
  POPULAR_SUPPLIER: "POPULAR_SUPPLIER",
  BRAND_TEST: "BRAND_TEST",
  FREE_SUPPLIER: "FREE_SUPPLIER",
  BRAND_NOVICE: "BRAND_NOVICE",
  BRAND_AMATEUR: "BRAND_AMATEUR",
  BRAND_PRO: "BRAND_PRO",
  BRAND_STARTER: "BRAND_STARTER",
  PRO_RESTAURANT: "PRO_RESTAURANT",
  IMAGE: "image",
  VIDEO: "video",
  CAMPAIGN: "campaign",
  REFERRED_AFFILIATE_CODE: "referredAffiliateCode",
  AFFILIATE_CODE: "affiliateCode",
  SUPPLIER_ID: "supplierId",
  ADDITIONAL_CREDIT_PURCHASE: "ADDITIONAL_CREDIT_PURCHASE",
  CURRENT_ADDITIONAL_CREDIT: "CURRENT_ADDITIONAL_CREDIT",
  ONETIME: "onetime",
  BRAND_CAMPAIGN_PAGE_LIMIT: "brandPromotionsPageLimit",
  BRAND_DRIP_CAMPAIGN_PAGE_LIMIT: "brandDripCampaignPageLimit",
  DISTRIBUTOR_PROMOTION_PAGE_LIMIT: "distributorPromotionsPageLimit",
  UNSAVED_DEAL: "unsavedDeal",
  LIFETIME_SUBSCRIPTION_OFFER_MODAL_SHOW: "LifetimeSubscriptionOfferModalShow",
  NO_AUTH_DEAL_SIGNUP_DEALID: "noAuthDealUserSignupDealId",
  REG_RESTAURANT_ID: "reg_restaurant_id",
  COUPON_CODE: "couponCode",
  ZIP_POSTAL_CODE: "Zip/postal code",
  ZIPCODE: "zipcode",
  CITY: "City",
  // CITY: "city",
  STATE: "state",
  STATE_PROVINCE: "State/Province",
  SETTINGS: "Settings",
  SUPPORT: "Support",
  DASHBOARD: "Dashboard",
  YOUR_CURRENT_SUBSCRIPTION: "Your Current Subscription",
  DOWNGRADE: "Downgrade",
  UPGRADE: "Upgrade",
  MORE_INFO_SARAH: "More info from Sarah",
  ENTER: "Enter",
  DEALS: "deals",
  DEALS_WITH_REPORTS: "deals_with_reports",
  DEFAULT_TRIAL_DAY: 30,
  DEALS_WITH_REPORTS_NO_SUBSCRIPTION: "deals_with_reports_no_subscription",
  TAB: "Tab",
  RESTAURANT_FEATURES: "restaurant_feature",
  FOH: "FOH",
  BRAND_PRESALE_MONTHLY: "BRAND_PRESALE_MONTHLY",
  BRAND_299_MONTH_PER_USER: "BRAND_299_MONTH_PER_USER",
  BRAND_PRESALE_LIFETIME: "BRAND_PRESALE_LIFETIME",
  PRESALE: "presale",
  TITLES: {
    SUB_TITLE: "KEXY Restaurant Webportal",
    SEPARATOR: " - ",
    BUSINESS_OPERATIONS: "Business Operations",
    MENU_PERFORMANCE: "Menu Performance",
    RESTAURANT_FEATURES: "Features - KEXY Restaurant Portal",
  },
  KEXY: "kexy",
  TRIALING: "trialing",
  DEAL_LIST_PRICE: "Deal",
  PRODUCT_OFFERING_NO_PRICE_LISTED: "Featured Product",
  INFORMATIVE_BRAND_MARKETING: "Informative",
  DEAL_DISTRIBUTOR: "DISTRIBUTOR",
  DEAL_REPS: "REPS",
  LIST_PRODUCT_ON_PLATFORM: "Featured Service",
  DEAL_WITH_PRICE: "deal",
  FEATURED_PRODUCT: "featured_product",
  INFORMATIVE: "informative",
  LISTING_OF_PRODUCT: "product_listing",
  ANNOUNCEMENT_KEY: "announcement",
  ANNOUNCEMENT_VALUE: "Announcement",
  FAV_CAMPAIGN: "My Favorite Campaigns",
  LIST_INVOICE: "List of Invoices",
  DESKTOP: "desktop",
  MOBILE: "mobile",
  DRAFT_DEAL: "draft_deal",
  YOUTUBE_URL: "youtube.com",
  YOUTU_BE_URL: "youtu.be",
  DUPLICATE: "duplicate",
  PROSPECTING: "Prospecting",
  LIFETIME: "Lifetime",
  NOVICE: "Novice",
  AMATEUR: "Amateur",
  STARTER: "Starter",
  VERIFIED: "verified",
  AMATEUR_BRAND_MONTH_PRICE: "599",
  AMATEUR_BRAND_YEAR_PRICE: "399",
  PRO_BRAND_MONTH_PRICE: "999",
  PRO_BRAND_YEAR_PRICE: "719",
  US: "US",
  LAST_7_DAYS: "Last 7 days",
  LAST_30_DAYS: "Last 30 days",
  LAST_365_DAYS: "Last 365 days",
  NONE: "none",
  SUBMIT: "Submit",
  CAMPAIGN_CREATED_SUCCESSFULLY: "campaignCreatedSuccessFully",
  CAMPAIGN_UPDATED_SUCCESSFULLY: "campaignUpdatedSuccessFully",
  EDIT: "edit",
  ARCHIVE: "archive",
  EMAIL_EXIST: "EMAIL_EXIST",
  BETA_REGISTER_EMAIL: "betaRegisterEmail",
  BETA_REGISTER_PASSWORD: "betaRegisterPassword",
  TITLE: "title",
  PRICE: "price",
  SCOTT_BRAND_ACCOUNT_ID: 95,
  PROSPECTING_CALENDLY_LINKS: "prospectingCalendlyLinks",
  PROSPECTING_WEBSITE: "prospectingWebsite",
  SALES_LEAD_SEARCH_PAYLOAD: "sales-lead-search-payload",
  SALES_LEAD_SEARCH_JOB_TITLES_PAYLOAD: "sales-lead-search-job-titles-payload",
  SALES_LEAD_EMAIL_TONE: "sales-lead-email-tone",
  SALES_LEAD_PRODUCT: "sales-lead-product",
  SALES_LEAD_PRODUCT_CATEGORY: "sales-lead-product-category",
  SALES_LEAD_PRODUCT_DESCRIPTION: "sales-lead-product-description",
  SALES_LEAD_PRODUCT_WEBSITE: "sales-lead-product-website",
  SALES_LEAD_PRODUCT_CALENDLY: "sales-lead-product-calendly",
  SALES_LEAD_SELECT_AREA_SEARCH_TYPE: "sales-lead-select-area-search-type",
  SALES_LEAD_ZIP_CODE_LIST: "sales-lead-zip-code-list",
  SALES_LEAD_CITY_LIST: "sales-lead-city-list",
  SALES_LEAD_STATE_LIST: "sales-lead-state-list",
  SALES_LEAD_NUMBER_OF_ESTABLISHMENT: "sales-lead-number-of-establishment",
  SALES_LEAD_ORGANIZATION_INDUSTRY_TAG_IDS: "sales-lead-organization-industry-tag-ids",
  SALES_LEAD_PERSON_SENIORITIES: "sales-lead-person-seniorities",
  SALES_LEAD_SELECTED_TARGETTED_PEOPLE: "sales-lead-selected-targetted-people",
  USERTOKEN: "userToken",
  INDUSTRIES: [
    { key: "5567e0e0736964198de70700", value: "Restaurants", isSelected: false },
    { key: "5567ce9d7369643bc19c0000", value: "Hospitality", isSelected: false },
  ],
  CAMPAIGN_DETAILS: "campaign_details",
  CAMPAIGN_CONTACT: "campaign_contacts",
  CAMPAIGN_PREVIEW: "campaign_preview",
  CAMPAIGN_SUBMITTED: "campaign_submitted",
  OFF_PREMISE: "Off Premise",
  ON_PREMISE_FOH: "On Premise (FOH)",
  ON_PREMISE_BOH: "On Premise (BOH)",
  DTC: "DTC (Direct-to-Consumer)",
  QUEUED: "queued",
  EMAIL_TONES: [
    { key: "Friendly", value: "Friendly" },
    { key: "Normal_human", value: "Normal Human" },
    { key: "Casual", value: "Casual" },
    { key: "Luxury", value: "Luxury" },
    { key: "Professional", value: "Professional" },
    { key: "Relaxed", value: "Relaxed" },
    { key: "Bold", value: "Bold" },
    { key: "Witty", value: "Witty" },
    { key: "Persuasive", value: "Persuasive" },
    { key: "Empathetic", value: "Empathetic" },
    { key: "Emoji", value: "Emoji" },
  ],
  EMAIL_LENGTH_KEYS: [
    { key: "short", value: "Short" },
    { key: "medium", value: "Medium" },
    { key: "long", value: "Long" },
  ],
  DRIP_CAMPAIGN: "Drip Campaigns",
  PROMOTIONS: "Promotions",
  SALES_LEAD_COUNTRY: "sales-lead-country",
  CAMPAIGN_CONTENT: "campaign_content",
  GENERATE_EMAILS: "generate_emails",
  TEST_EMAIL_URL: "test_email_url",
  URL_PURCHASE_LINK: "url_purchase_link",
  USA_STATES_COUNT: 51,
  CANADA_STATES_COUNT: 10,
  AMATEUR_SUBSCRIPTION_PRICE_MONTH: "199",
  STARTER_SUBSCRIPTION_PRICE_MONTH: "299",
  STARTER_SUBSCRIPTION_PRICE_YEAR: "239",
  PRO_SUBSCRIPTION_PRICE_MONTH: "299",
  AMATEUR_SUBSCRIPTION_PRICE_YEAR: "159",
  PRO_SUBSCRIPTION_PRICE_YEAR: "239",
  MONTHLY_CREDIT_INFO: {
    img: "/assets/images/monthly-credit-img.png",
    title: "Monthly credit usage",
    progressColor: "blue",
    descText: "monthlyCreditText",
    creditTextColor: "#12A5F4",
    total_credits: 0,
    remaining_credits: 0,
    buyMoreBtnShow: false,
  },
  ADDITIONAL_CREDIT_INFO: {
    img: "/assets/images/additional-credit-img.png",
    title: "Additional credit usage",
    progressColor: "orange",
    descText: "Additional credits can be purchased for a nominal cost.  Our Fair Use Policy limits plan to 25,000 credits per cycle to prevent abuse.",
    creditTextColor: "#F46112",
    total_credits: 0,
    remaining_credits: 0,
    buyMoreBtnShow: true,
  },
  SUBSCRIPTION_TYPES_DATA: [],
  LIFETIME_DEAL: "Lifetime Deal",
  LIFETIME_SUBSCRIPTION_DATA: {
    icon: "/assets/icon/double-star-blue-icon.svg",
    title: "Lifetime Deal",
    subscriptionType: "BRAND_PRESALE_LIFETIME",
    lifeTime: true,
    active: false,
    price: "599",
    listYourProducts: "Unlimited",
    emailCredits: "250",
    leadsProspectingTool: true,
    aiDripCampaignCreator: true,
    campaignPerformanceMetrics: true,
    customLandingPages: true,
    purchaseAdditionalLeads: true,
    accountSetupAndTraining: false,
    showChangePlanOrCurrentPlanBtn: true,
  },
  NOVICE_SUBSCRIPTION_DATA: {
    icon: "/assets/icon/double-star-blue-icon.svg",
    title: "Novice",
    subscriptionType: "BRAND_NOVICE",
    active: false,
    price: "0",
    listYourProducts: "Unlimited",
    emailCredits: "50",
    leadsProspectingTool: true,
    aiDripCampaignCreator: true,
    campaignPerformanceMetrics: true,
    customLandingPages: true,
    purchaseAdditionalLeads: true,
    accountSetupAndTraining: false,
    showChangePlanOrCurrentPlanBtn: true,
  },
  AMATEUR_SUBSCRIPTION_DATA: {
    icon: "/assets/icon/blue-amateur-subscription-icon.svg",
    title: "Amateur",
    subscriptionType: "BRAND_AMATEUR",
    active: false,
    price: "199",
    listYourProducts: "Unlimited",
    emailCredits: "1,000",
    leadsProspectingTool: true,
    aiDripCampaignCreator: true,
    campaignPerformanceMetrics: true,
    customLandingPages: true,
    purchaseAdditionalLeads: true,
    accountSetupAndTraining: true,
    showChangePlanOrCurrentPlanBtn: true,
  },
  PRO_SUBSCRIPTION_DATA: {
    icon: "/assets/icon/blue-pro-subscription-icon.svg",
    title: "Pro",
    subscriptionType: "BRAND_PRO",
    active: false,
    price: "299",
    listYourProducts: "Unlimited",
    emailCredits: "2,500",
    leadsProspectingTool: true,
    aiDripCampaignCreator: true,
    campaignPerformanceMetrics: true,
    customLandingPages: true,
    purchaseAdditionalLeads: true,
    accountSetupAndTraining: true,
    showChangePlanOrCurrentPlanBtn: true,
  },
  ENTERPRISE_SUBSCRIPTION_DATA: {
    icon: "/assets/icon/blue-enterprise-subscription-icon.svg",
    title: "Enterprise",
    price: "",
    active: false,
    listYourProducts: "Unlimited",
    emailCredits: "-",
    aiGeneratedSalesEmail: true,
    campaignPerformanceMatrics: true,
    featuredDealsAndProductCampaigns: true,
    informativeMarketingCampaigns: true,
    preferredPlacementOfProducts: true,
    priorityMatching: true,
    accountSetupAndTraining: true,
    showChangePlanOrCurrentPlanBtn: false,
  },
  STARTER_SUBSCRIPTION_DATA: {
    icon: "/assets/icon/blue-enterprise-subscription-icon.svg",
    title: "Starter",
    price: "299",
    active: false,
    subscriptionType: "BRAND_STARTER",
    listYourProducts: "Unlimited",
    emailCredits: "250",
    aiGeneratedSalesEmail: true,
    campaignPerformanceMatrics: true,
    featuredDealsAndProductCampaigns: true,
    informativeMarketingCampaigns: true,
    preferredPlacementOfProducts: true,
    priorityMatching: false,
    accountSetupAndTraining: false,
    showChangePlanOrCurrentPlanBtn: true,
  },
  INDUSTRY: "industry",
  COMPANIES: "Companies",
  CONSUMERS: "Consumers",
  BROAD_CAMPAIGN: "Broad Campaign",
  FOCUSED_CAMPAIGN: "Focused Campaign",
  IMPORT_CAMPAIGN: "Import Campaign",
  WHO_COVER: "Objective: [i.e. request feedback, tell a story, etc.]\n\n" +
    "Email content: [What's the email about? Enter your email's main points here\n" +
    "- point 1\n" +
    "- point 2]\n\n" +
    "Call to action: [i.e. - book a meeting, purchase now]",
  YES: "yes",
  NO: "no",
  KEXY_SIGNUP_INDUSTRY_OPTIONS: [
    // {key: "restaurant", value: "Restaurant/Bar", type: "restaurant"},
    // {key: "brand", value: "Brand/Supplier", type: "brand"},
    { key: "accounting", value: "Accounting", type: "brand" },
    { key: "advertising_services", value: "Advertising Services", type: "brand" },
    { key: "Artists_and_writers", value: "Artists & Writers", type: "brand" },
    { key: "banking", value: "Banking", type: "brand" },
    { key: "blockchain_services", value: "Blockchain Services", type: "brand" },
    { key: "business_consulting_and_services", value: "Business Consulting & Services", type: "brand" },
    { key: "dentists", value: "Dentists", type: "brand" },
    { key: "education", value: "Education", type: "brand" },
    { key: "financial_services", value: "Financial Services", type: "brand" },
    { key: "food_and_beverage", value: "Food & Beverage", type: "brand" },
    { key: "fundraising", value: "Fundraising", type: "brand" },
    { key: "hospitality", value: "Hospitality", type: "brand" },
    { key: "insurance", value: "Insurance", type: "brand" },
    { key: "interior_design", value: "Interior Design", type: "brand" },
    { key: "investment_banking", value: "Investment Banking", type: "brand" },
    { key: "legal_services", value: "Legal Services", type: "brand" },
    { key: "medical_practices", value: "Medical Practices", type: "brand" },
    { key: "physicians", value: "Physicians", type: "brand" },
    { key: "real_estate", value: "Real Estate", type: "brand" },
    { key: "restaurant_and_bars", value: "Restaurant & Bars", type: "brand" },
    { key: "retail_apparel_and_fashion", value: "Retail Apparel & Fashion", type: "brand" },
    { key: "software_or_technology", value: "Software/Technology", type: "brand" },
    { key: "telecommunications", value: "Telecommunications", type: "brand" },
    { key: "venture_capital_and_private_equity", value: "Venture Capital & Private Equity", type: "brand" },
    { key: "other", value: "Other", type: "brand" },
  ],
  PURCHASE: "purchase",
  VISIT_WEBSITE: "visitWebsite",
  SEND_MESSAGE_CALL: "sendMessageCall",
  CUSTOM_BTN: "customBtn",
  PURCHASE_BTN_TEXT: "Purchase",
  VISIT_WEBSITE_BTN_TEXT: "Visit Website",
  SEND_MESSAGE_CALL_BTN_TEXT: "Send Message/Call",
  SEND_EMAIL: "Send Email",
  FOOD_AND_BEVERAGE: "Food & Beverage",
  UNITED_STATES: "United States",
  CANADA: "Canada",
  COUNTRIES: [
    { key: "unitedStates", value: "United States" },
    { key: "canada", value: "Canada" },
  ],
  MARKETING_STATUS_OPTIONS: [
    { key: "subscribed", value: "Subscribed" },
    { key: "unsubscribed", value: "Unsubscribed" },
  ],
  EMAIL_STATUS_OPTIONS: [
    { key: "invalid", value: "Invalid" },
    { key: "verified", value: "Valid" },
    { key: "unverified", value: "Unverified" },
    { key: "catch-all", value: "Catch-all" },
  ],
  LABEL_COLOR_OPTIONS: [
    { labelBgColor: "#0078ff", labelTextColor: "#ffffff" },
    { labelBgColor: "#ddedff", labelTextColor: "#000000" },
    { labelBgColor: "#ff3541", labelTextColor: "#ffffff" },
    { labelBgColor: "#ffe4e6", labelTextColor: "#000000" },
    { labelBgColor: "#ff9900", labelTextColor: "#ffffff" },
    { labelBgColor: "#ffe6c0", labelTextColor: "#000000" },
    { labelBgColor: "#008377", labelTextColor: "#ffffff" },
    { labelBgColor: "#45b3a8", labelTextColor: "#ffffff" },
    { labelBgColor: "#3b3a4d", labelTextColor: "#ffffff" },
    { labelBgColor: "#757585", labelTextColor: "#ffffff" },
    { labelBgColor: "#35459e", labelTextColor: "#ffffff" },
    { labelBgColor: "#bbc5fb", labelTextColor: "#000000" },
    { labelBgColor: "#5938d9", labelTextColor: "#ffffff" },
    { labelBgColor: "#907ce3", labelTextColor: "#ffffff" },
  ],
  CONTACTS: "Contacts",
  COMPANY_DETAILS: "Company Details",
  CONTACTS_TABLE_PAGINATION_LIMIT: "contacts_table_pagination_limit",
  LISTS_TABLE_PAGINATION_LIMIT: "lists_table_pagination_limit",
  SUPPRESSION_TABLE_PAGINATION_LIMIT: "suppression_table_pagination_limit",
  EMAIL_SETTINGS_DAYS: [
    { key: "everyday", value: "Everyday" },
    { key: "mon-fri", value: "Mon - Fri" },
    { key: "sat-sun", value: "Sat - Sun" },
    { key: "monday", value: "Monday" },
    { key: "tuesday", value: "Tuesday" },
    { key: "wednesday", value: "Wednesday" },
    { key: "thursday", value: "Thursday" },
    { key: "friday", value: "Friday" },
  ],
  EVERYDAY: "Everyday",
  EMAIL_SETTINGS_TIMES: [
    { key: "12:00pm", value: "12:00 PM" },
    { key: "12:30pm", value: "12:30 PM" },
    { key: "1:00pm", value: "1:00 PM" },
    { key: "1:30pm", value: "1:30 PM" },
    { key: "2:00pm", value: "2:00 PM" },
    { key: "2:30pm", value: "2:30 PM" },
    { key: "3:00pm", value: "3:00 PM" },
    { key: "3:30pm", value: "3:30 PM" },
    { key: "4:00pm", value: "4:00 PM" },
    { key: "4:30pm", value: "4:30 PM" },
    { key: "5:00pm", value: "5:00 PM" },
    { key: "5:30pm", value: "5:30 PM" },
    { key: "6:00pm", value: "6:00 PM" },
    { key: "6:30pm", value: "6:30 PM" },
    { key: "7:00pm", value: "7:00 PM" },
    { key: "7:30pm", value: "7:30 PM" },
    { key: "8:00pm", value: "8:00 PM" },
    { key: "8:30pm", value: "8:30 PM" },
    { key: "9:00pm", value: "9:00 PM" },
    { key: "9:30pm", value: "9:30 PM" },
    { key: "10:00pm", value: "10:00 PM" },
    { key: "10:30pm", value: "10:30 PM" },
    { key: "11:00pm", value: "11:00 PM" },
    { key: "11:30pm", value: "11:30 PM" },
    { key: "12:00am", value: "12:00 AM" },
    { key: "12:30am", value: "12:30 AM" },
    { key: "1:00am", value: "1:00 AM" },
    { key: "1:30am", value: "1:30 AM" },
    { key: "2:00am", value: "2:00 AM" },
    { key: "2:30am", value: "2:30 AM" },
    { key: "3:00am", value: "3:00 AM" },
    { key: "3:30am", value: "3:30 AM" },
    { key: "4:00am", value: "4:00 AM" },
    { key: "4:30am", value: "4:30 AM" },
    { key: "5:00am", value: "5:00 AM" },
    { key: "5:30am", value: "5:30 AM" },
    { key: "6:00am", value: "6:00 AM" },
    { key: "6:30am", value: "6:30 AM" },
    { key: "7:00am", value: "7:00 AM" },
    { key: "7:30am", value: "7:30 AM" },
    { key: "8:00am", value: "8:00 AM" },
    { key: "8:30am", value: "8:30 AM" },
    { key: "9:00am", value: "9:00 AM" },
    { key: "9:30am", value: "9:30 AM" },
    { key: "10:00am", value: "10:00 AM" },
    { key: "10:30am", value: "10:30 AM" },
    { key: "11:00am", value: "11:00 AM" },
    { key: "11:30am", value: "11:30 AM" },
  ],
  CLICKS: "Clicks",
  OPENS: "Opens",
  CLICK: "click",
  OPEN: "open",
  REPLY: "reply",
  DEPARTMENTS_AND_JOB_FUNCTIONS: [
    {
      isOpen: false,
      isSelected: false,
      name: "C-Suite",
      key: "c_suite",
      subItems: [
        {
          isSelected: false,
          name: "Executive",
          key: "executive",
        },
        {
          isSelected: false,
          name: "Finance Executive",
          key: "finance_executive",
        },
        {
          isSelected: false,
          name: "Founder",
          key: "founder",
        },
        {
          isSelected: false,
          name: "Human Resources Executive",
          key: "human_resources_executive",
        },
        {
          isSelected: false,
          name: "Information Technology Executive",
          key: "information_technology_executive",
        },
        {
          isSelected: false,
          name: "Legal Executive",
          key: "legal_executive",
        },
        {
          isSelected: false,
          name: "Marketing Executive",
          key: "marketing_executive",
        },
        {
          isSelected: false,
          name: "Medical & Health Executive",
          key: "medical_health_executive",
        },
        {
          isSelected: false,
          name: "Operations Executive",
          key: "operations_executive",
        },
        {
          isSelected: false,
          name: "Sales Leader",
          key: "sales_executive",
        },
      ],
    },
    {
      isOpen: false,
      isSelected: false,
      name: "Product",
      key: "product_management",
      subItems: [
        {
          isSelected: false,
          name: "Product Development",
          key: "product_development",
        },
        {
          isSelected: false,
          name: "Product Management",
          key: "product_management",
        },
      ],
    },
    {
      isOpen: false,
      isSelected: false,
      name: "Engineering & Technical",
      key: "master_engineering_technical",
      subItems: [
        {
          isSelected: false,
          name: "Artificial Intelligence / Machine Learning",
          key: "artificial_intelligence_machine_learning",
        },
        {
          isSelected: false,
          name: "Bioengineering",
          key: "bioengineering",
        },
        {
          isSelected: false,
          name: "Biometrics",
          key: "biometrics",
        },
        {
          isSelected: false,
          name: "Business Intelligence",
          key: "business_intelligence",
        },
        {
          isSelected: false,
          name: "Chemical Engineering",
          key: "chemical_engineering",
        },
        {
          isSelected: false,
          name: "Cloud / Mobility",
          key: "cloud_mobility",
        },
        {
          isSelected: false,
          name: "Data Science",
          key: "data_science",
        },
        {
          isSelected: false,
          name: "DevOps",
          key: "devops",
        },
        {
          isSelected: false,
          name: "Digital Transformation",
          key: "digital_transformation",
        },
        {
          isSelected: false,
          name: "Emerging Technology / Innovation",
          key: "emerging_technology_innovation",
        },
        {
          isSelected: false,
          name: "Engineering & Technical",
          key: "engineering_technical",
        },
        {
          isSelected: false,
          name: "Industrial Engineering",
          key: "industrial_engineering",
        },
        {
          isSelected: false,
          name: "Mechanic",
          key: "mechanic",
        },
        {
          isSelected: false,
          name: "Mobile Development",
          key: "mobile_development",
        },
        {
          isSelected: false,
          name: "Project Management",
          key: "project_management",
        },
        {
          isSelected: false,
          name: "Research & Development",
          key: "research_development",
        },
        {
          isSelected: false,
          name: "Scrum Master / Agile Coach",
          key: "scrum_master_agile_coach",
        },
        {
          isSelected: false,
          name: "Software Development",
          key: "software_development",
        },
        {
          isSelected: false,
          name: "Support / Technical Services",
          key: "support_technical_services",
        },
        {
          isSelected: false,
          name: "Technician",
          key: "technician",
        },
        {
          isSelected: false,
          name: "Technology Operations",
          key: "technology_operations",
        },
        {
          isSelected: false,
          name: "Test / Quality Assurance",
          key: "test_quality_assurance",
        },
        {
          isSelected: false,
          name: "UI / UX",
          key: "ui_ux",
        },
        {
          isSelected: false,
          name: "Web Development",
          key: "web_development",
        },
      ],
    },
    {
      isOpen: false,
      isSelected: false,
      name: "Design",
      key: "design",
      subItems: [
        {
          isSelected: false,
          name: "All Design",
          key: "all_design",
        },
        {
          isSelected: false,
          name: "Product or UI/UX Design",
          key: "product_ui_ux_design",
        },
        {
          isSelected: false,
          name: "Graphic / Visual / Brand Design",
          key: "graphic_design",
        },
      ],
    },
    {
      isOpen: false,
      isSelected: false,
      name: "Education",
      key: "education",
      subItems: [
        {
          isSelected: false,
          name: "Teacher",
          key: "teacher",
        },
        {
          isSelected: false,
          name: "Principal",
          key: "principal",
        },
        {
          isSelected: false,
          name: "Superintendent",
          key: "superintendent",
        },
        {
          isSelected: false,
          name: "Professor",
          key: "professor",
        },
      ],
    },
    {
      isOpen: false,
      isSelected: false,
      name: "Finance",
      key: "master_finance",
      subItems: [
        {
          isSelected: false,
          name: "Accounting",
          key: "accounting",
        },
        {
          isSelected: false,
          name: "Finance",
          key: "finance",
        },
        {
          isSelected: false,
          name: "Financial Planning & Analysis",
          key: "financial_planning_analysis",
        },
        {
          isSelected: false,
          name: "Financial Reporting",
          key: "financial_reporting",
        },
        {
          isSelected: false,
          name: "Financial Strategy",
          key: "financial_strategy",
        },
        {
          isSelected: false,
          name: "Financial Systems",
          key: "financial_systems",
        },
        {
          isSelected: false,
          name: "Internal Audit & Control",
          key: "internal_audit_control",
        },
        {
          isSelected: false,
          name: "Investor Relations",
          key: "investor_relations",
        },
        {
          isSelected: false,
          name: "Mergers & Acquisitions",
          key: "mergers_acquisitions",
        },
        {
          isSelected: false,
          name: "Real Estate Finance",
          key: "real_estate_finance",
        },
        {
          isSelected: false,
          name: "Financial Risk",
          key: "financial_risk",
        },
        {
          isSelected: false,
          name: "Shared Services",
          key: "shared_services",
        },
        {
          isSelected: false,
          name: "Sourcing / Procurement",
          key: "sourcing_procurement",
        },
        {
          isSelected: false,
          name: "Tax",
          key: "tax",
        },
        {
          isSelected: false,
          name: "Treasury",
          key: "treasury",
        },
      ],
    },
    {
      isOpen: false,
      isSelected: false,
      name: "Human Resources",
      key: "master_human_resources",
      subItems: [
        {
          isSelected: false,
          name: "Compensation & Benefits",
          key: "compensation_benefits",
        },
        {
          isSelected: false,
          name: "Culture, Diversity & Inclusion",
          key: "culture_diversity_inclusion",
        },
        {
          isSelected: false,
          name: "Employee & Labor Relations",
          key: "employee_labor_relations",
        },
        {
          isSelected: false,
          name: "Health & Safety",
          key: "health_safety",
        },
        {
          isSelected: false,
          name: "Human Resource Information System",
          key: "human_resource_information_system",
        },
        {
          isSelected: false,
          name: "Human Resources",
          key: "human_resources",
        },
        {
          isSelected: false,
          name: "HR Business Partner",
          key: "hr_business_partner",
        },
        {
          isSelected: false,
          name: "Learning & Development",
          key: "learning_development",
        },
        {
          isSelected: false,
          name: "Organizational Development",
          key: "organizational_development",
        },
        {
          isSelected: false,
          name: "Recruiting & Talent Acquisition",
          key: "recruiting_talent_acquisition",
        },
        {
          isSelected: false,
          name: "Talent Management",
          key: "talent_management",
        },
        {
          isSelected: false,
          name: "Workforce Management",
          key: "workforce_mangement",
        },
        {
          isSelected: false,
          name: "People Operations",
          key: "people_operations",
        },
      ],
    },
    {
      isOpen: false,
      isSelected: false,
      name: "Information Technology",
      key: "master_information_technology",
      subItems: [
        {
          isSelected: false,
          name: "Application Development",
          key: "application_development",
        },
        {
          isSelected: false,
          name: "Business Service Management / ITSM",
          key: "business_service_management_itsm",
        },
        {
          isSelected: false,
          name: "Collaboration & Web App",
          key: "collaboration_web_app",
        },
        {
          isSelected: false,
          name: "Data Center",
          key: "data_center",
        },
        {
          isSelected: false,
          name: "Data Warehouse",
          key: "data_warehouse",
        },
        {
          isSelected: false,
          name: "Database Administration",
          key: "database_administration",
        },
        {
          isSelected: false,
          name: "eCommerce Development",
          key: "ecommerce_development",
        },
        {
          isSelected: false,
          name: "Enterprise Architecture",
          key: "enterprise_architecture",
        },
        {
          isSelected: false,
          name: "Help Desk / Desktop Services",
          key: "help_desk_desktop_services",
        },
        {
          isSelected: false,
          name: "HR / Financial / ERP Systems",
          key: "hr_financial_erp_systems",
        },
        {
          isSelected: false,
          name: "Information Security",
          key: "information_security",
        },
        {
          isSelected: false,
          name: "Information Technology",
          key: "information_technology",
        },
        {
          isSelected: false,
          name: "Infrastructure",
          key: "infrastructure",
        },
        {
          isSelected: false,
          name: "IT Asset Management",
          key: "it_asset_management",
        },
        {
          isSelected: false,
          name: "IT Audit / IT Compliance",
          key: "it_audit_it_compliance",
        },
        {
          isSelected: false,
          name: "IT Operations",
          key: "it_operations",
        },
        {
          isSelected: false,
          name: "IT Procurement",
          key: "it_procurement",
        },
        {
          isSelected: false,
          name: "IT Strategy",
          key: "it_strategy",
        },
        {
          isSelected: false,
          name: "IT Training",
          key: "it_training",
        },
        {
          isSelected: false,
          name: "Networking",
          key: "networking",
        },
        {
          isSelected: false,
          name: "Project & Program Management",
          key: "project_program_management",
        },
        {
          isSelected: false,
          name: "Quality Assurance",
          key: "quality_assurance",
        },
        {
          isSelected: false,
          name: "Retail / Store Systems",
          key: "retail_store_systems",
        },
        {
          isSelected: false,
          name: "Servers",
          key: "servers",
        },
        {
          isSelected: false,
          name: "Storage & Disaster Recovery",
          key: "storage_disaster_recovery",
        },
        {
          isSelected: false,
          name: "Telecommunications",
          key: "telecommunications",
        },
        {
          isSelected: false,
          name: "Virtualization",
          key: "virtualization",
        },
      ],
    },
    {
      isOpen: false,
      isSelected: false,
      name: "Legal",
      key: "master_legal",
      subItems: [
        {
          isSelected: false,
          name: "Acquisitions",
          key: "acquisitions",
        },
        {
          isSelected: false,
          name: "Compliance",
          key: "compliance",
        },
        {
          isSelected: false,
          name: "Contracts",
          key: "contracts",
        },
        {
          isSelected: false,
          name: "Corporate Secretary",
          key: "corporate_secretary",
        },
        {
          isSelected: false,
          name: "eDiscovery",
          key: "ediscovery",
        },
        {
          isSelected: false,
          name: "Ethics",
          key: "ethics",
        },
        {
          isSelected: false,
          name: "Governance",
          key: "governance",
        },
        {
          isSelected: false,
          name: "Governmental Affairs & Regulatory Law",
          key: "governmental_affairs_regulatory_law",
        },
        {
          isSelected: false,
          name: "Intellectual Property & Patent",
          key: "intellectual_property_patent",
        },
        {
          isSelected: false,
          name: "Labor & Employment",
          key: "labor_employment",
        },
        {
          isSelected: false,
          name: "Lawyer / Attorney",
          key: "lawyer_attorney",
        },
        {
          isSelected: false,
          name: "Legal",
          key: "legal",
        },
        {
          isSelected: false,
          name: "Legal Counsel",
          key: "legal_counsel",
        },
        {
          isSelected: false,
          name: "Legal Operations",
          key: "legal_operations",
        },
        {
          isSelected: false,
          name: "Litigation",
          key: "litigation",
        },
        {
          isSelected: false,
          name: "Privacy",
          key: "privacy",
        },
      ],
    },
    {
      isOpen: false,
      isSelected: false,
      name: "Marketing",
      key: "master_marketing",
      subItems: [
        {
          isSelected: false,
          name: "Advertising",
          key: "advertising",
        },
        {
          isSelected: false,
          name: "Brand Management",
          key: "brand_management",
        },
        {
          isSelected: false,
          name: "Content Marketing",
          key: "content_marketing",
        },
        {
          isSelected: false,
          name: "Customer Experience",
          key: "customer_experience",
        },
        {
          isSelected: false,
          name: "Customer Marketing",
          key: "customer_marketing",
        },
        {
          isSelected: false,
          name: "Demand Generation",
          key: "demand_generation",
        },
        {
          isSelected: false,
          name: "Digital Marketing",
          key: "digital_marketing",
        },
        {
          isSelected: false,
          name: "eCommerce Marketing",
          key: "ecommerce_marketing",
        },
        {
          isSelected: false,
          name: "Event Marketing",
          key: "event_marketing",
        },
        {
          isSelected: false,
          name: "Field Marketing",
          key: "field_marketing",
        },
        {
          isSelected: false,
          name: "Lead Generation",
          key: "lead_generation",
        },
        {
          isSelected: false,
          name: "Marketing",
          key: "marketing",
        },
        {
          isSelected: false,
          name: "Marketing Analytics / Insights",
          key: "marketing_analytics_insights",
        },
        {
          isSelected: false,
          name: "Marketing Communications",
          key: "marketing_communications",
        },
        {
          isSelected: false,
          name: "Marketing Operations",
          key: "marketing_operations",
        },
        {
          isSelected: false,
          name: "Product Marketing",
          key: "product_marketing",
        },
        {
          isSelected: false,
          name: "Public Relations",
          key: "public_relations",
        },
        {
          isSelected: false,
          name: "Search Engine Optimization / Pay Per Click",
          key: "search_engine_optimization_pay_per_click",
        },
        {
          isSelected: false,
          name: "Social Media Marketing",
          key: "social_media_marketing",
        },
        {
          isSelected: false,
          name: "Strategic Communications",
          key: "strategic_communications",
        },
        {
          isSelected: false,
          name: "Technical Marketing",
          key: "technical_marketing",
        },
      ],
    },
    {
      isOpen: false,
      isSelected: false,
      name: "Medical & Health",
      key: "medical_health",
      subItems: [
        {
          isSelected: false,
          name: "Anesthesiology",
          key: "anesthesiology",
        },
        {
          isSelected: false,
          name: "Chiropractics",
          key: "chiropractics",
        },
        {
          isSelected: false,
          name: "Clinical Systems",
          key: "clinical_systems",
        },
        {
          isSelected: false,
          name: "Dentistry",
          key: "dentistry",
        },
        {
          isSelected: false,
          name: "Dermatology",
          key: "dermatology",
        },
        {
          isSelected: false,
          name: "Doctors / Physicians",
          key: "doctors_physicians",
        },
        {
          isSelected: false,
          name: "Epidemiology",
          key: "epidemiology",
        },
        {
          isSelected: false,
          name: "First Responder",
          key: "first_responder",
        },
        {
          isSelected: false,
          name: "Infectious Disease",
          key: "infectious_disease",
        },
        {
          isSelected: false,
          name: "Medical Administration",
          key: "medical_administration",
        },
        {
          isSelected: false,
          name: "Medical Education & Training",
          key: "medical_education_training",
        },
        {
          isSelected: false,
          name: "Medical Research",
          key: "medical_research",
        },
        {
          isSelected: false,
          name: "Medicine",
          key: "medicine",
        },
        {
          isSelected: false,
          name: "Neurology",
          key: "neurology",
        },
        {
          isSelected: false,
          name: "Nursing",
          key: "nursing",
        },
        {
          isSelected: false,
          name: "Nutrition & Dietetics",
          key: "nutrition_dietetics",
        },
        {
          isSelected: false,
          name: "Obstetrics / Gynecology",
          key: "obstetrics_gynecology",
        },
        {
          isSelected: false,
          name: "Oncology",
          key: "oncology",
        },
        {
          isSelected: false,
          name: "Ophthalmology",
          key: "opthalmology",
        },
        {
          isSelected: false,
          name: "Optometry",
          key: "optometry",
        },
        {
          isSelected: false,
          name: "Orthopedics",
          key: "orthopedics",
        },
        {
          isSelected: false,
          name: "Pathology",
          key: "pathology",
        },
        {
          isSelected: false,
          name: "Pediatrics",
          key: "pediatrics",
        },
        {
          isSelected: false,
          name: "Pharmacy",
          key: "pharmacy",
        },
        {
          isSelected: false,
          name: "Physical Therapy",
          key: "physical_therapy",
        },
        {
          isSelected: false,
          name: "Psychiatry",
          key: "psychiatry",
        },
        {
          isSelected: false,
          name: "Psychology",
          key: "psychology",
        },
        {
          isSelected: false,
          name: "Public Health",
          key: "public_health",
        },
        {
          isSelected: false,
          name: "Radiology",
          key: "radiology",
        },
        {
          isSelected: false,
          name: "Social Work",
          key: "social_work",
        },
      ],
    },
    {
      isOpen: false,
      isSelected: false,
      name: "Operations",
      key: "master_operations",
      subItems: [
        {
          isSelected: false,
          name: "Call Center",
          key: "call_center",
        },
        {
          isSelected: false,
          name: "Construction",
          key: "construction",
        },
        {
          isSelected: false,
          name: "Corporate Strategy",
          key: "corporate_strategy",
        },
        {
          isSelected: false,
          name: "Customer Service / Support",
          key: "customer_service_support",
        },
        {
          isSelected: false,
          name: "Enterprise Resource Planning",
          key: "enterprise_resource_planning",
        },
        {
          isSelected: false,
          name: "Facilities Management",
          key: "facilities_management",
        },
        {
          isSelected: false,
          name: "Leasing",
          key: "leasing",
        },
        {
          isSelected: false,
          name: "Logistics",
          key: "logistics",
        },
        {
          isSelected: false,
          name: "Office Operations",
          key: "office_operations",
        },
        {
          isSelected: false,
          name: "Operations",
          key: "operations",
        },
        {
          isSelected: false,
          name: "Physical Security",
          key: "physical_security",
        },
        {
          isSelected: false,
          name: "Project Development",
          key: "project_development",
        },
        {
          isSelected: false,
          name: "Quality Management",
          key: "quality_management",
        },
        {
          isSelected: false,
          name: "Real Estate",
          key: "real_estate",
        },
        {
          isSelected: false,
          name: "Safety",
          key: "safety",
        },
        {
          isSelected: false,
          name: "Store Operations",
          key: "store_operations",
        },
        {
          isSelected: false,
          name: "Supply Chain",
          key: "supply_chain",
        },
      ],
    },
    {
      isOpen: false,
      isSelected: false,
      name: "Sales",
      key: "master_sales",
      subItems: [
        {
          isSelected: false,
          name: "Account Management",
          key: "account_management",
        },
        {
          isSelected: false,
          name: "Business Development",
          key: "business_development",
        },
        {
          isSelected: false,
          name: "Channel Sales",
          key: "channel_sales",
        },
        {
          isSelected: false,
          name: "Customer Retention & Development",
          key: "customer_retention_development",
        },
        {
          isSelected: false,
          name: "Customer Success",
          key: "customer_success",
        },
        {
          isSelected: false,
          name: "Field / Outside Sales",
          key: "field_outside_sales",
        },
        {
          isSelected: false,
          name: "Inside Sales",
          key: "inside_sales",
        },
        {
          isSelected: false,
          name: "Partnerships",
          key: "partnerships",
        },
        {
          isSelected: false,
          name: "Revenue Operations",
          key: "revenue_operations",
        },
        {
          isSelected: false,
          name: "Sales",
          key: "sales",
        },
        {
          isSelected: false,
          name: "Sales Enablement",
          key: "sales_enablement",
        },
        {
          isSelected: false,
          name: "Sales Engineering",
          key: "sales_engineering",
        },
        {
          isSelected: false,
          name: "Sales Operations",
          key: "sales_operations",
        },
        {
          isSelected: false,
          name: "Sales Training",
          key: "sales_training",
        },
      ],
    },
    {
      isOpen: false,
      isSelected: false,
      name: "Consulting",
      key: "consulting",
      subItems: [
        {
          isSelected: false,
          name: "Consultant",
          key: "consultant",
        },
      ],
    },
  ],
  TECHNOLOGY_OPTIONS: [
    {
      key: "mobile_friendly",
      value: "Mobile Friendly",
      technologySection: "",
      isSelected: false,
    },
    {
      key: "wordpress_org",
      value: "Wordpress.org",
      technologySection: "",
      isSelected: false,
    },
    {
      key: "google_font_api",
      value: "Google Font API",
      technologySection: "",
      isSelected: false,
    },
    {
      key: "google_tag_manager",
      value: "Google Tag Manager",
      technologySection: "",
      isSelected: false,
    },
    {
      key: "apache",
      value: "Apache",
      technologySection: "",
      isSelected: false,
    },
    {
      key: "nginx",
      value: "Nginx",
      technologySection: "",
      isSelected: false,
    },
    {
      key: "outlook",
      value: "Outlook",
      technologySection: "",
      isSelected: false,
    },
    {
      key: "google_analytics",
      value: "Google Analytics",
      technologySection: "",
      isSelected: false,
    },
    {
      key: "gmail",
      value: "Gmail",
      technologySection: "",
      isSelected: false,
    },
    {
      key: "google_apps",
      value: "Google Apps",
      technologySection: "",
      isSelected: false,
    },
    {
      key: "bootstrap_framework",
      value: "Bootstrap Framework",
      technologySection: "",
      isSelected: false,
    },
    {
      key: "recaptcha",
      value: "reCAPTCHA",
      technologySection: "",
      isSelected: false,
    },
    {
      key: "amazon_aws",
      value: "Amazon AWS",
      technologySection: "",
      isSelected: false,
    },
    {
      key: "facebook_login",
      value: "Facebook Login (Connect)",
      technologySection: "",
      isSelected: false,
    },
    {
      key: "remote",
      value: "Remote",
      technologySection: "",
      isSelected: false,
    },
    {
      key: "youtube",
      value: "Youtube",
      technologySection: "",
      isSelected: false,
    },
    {
      key: "facebook_widget",
      value: "Facebook Widget",
      technologySection: "",
      isSelected: false,
    },
    {
      key: "office_365",
      value: "Microsoft Office 365",
      technologySection: "",
      isSelected: false,
    },
    {
      key: "facebook_web_custom_audience",
      value: "Facebook Custom Audience",
      technologySection: "",
      isSelected: false,
    },
    {
      key: "google_cloud_hosting",
      value: "Google Cloud Hosting",
      technologySection: "",
      isSelected: false,
    },
  ],
  COMPANY: "Company",
  EXCLUDED: "Excluded",
  PAST: "Past",
  SMTP_PORTS: [
    { key: "25", value: "25" },
    { key: "465", value: "465" },
    { key: "2525", value: "2525" },
    { key: "587", value: "587" },
  ],
  ORGANIZATION_DOMAIN: "organization_domain",
  LANDING_PAGE: "Landing Page",
};
