export const environment = {
  production: false,
  baseUrl: "https://devapi.getkexy.com/devkexyapi/v1/",
  imageUrl: "https://d2m1cdle9kqn71.cloudfront.net/",
  externalAssetUrl: "https://d2m1cdle9kqn71.cloudfront.net/KEXY_Connect_assets/",
  siteUrl: "https://devclient.getkexy.com",
  distributorPortalUrl: "https://devclient.getkexy.com/distributor/",
  supplierPortalUrl: "https://devclient.getkexy.com/brand/",
  socketUri: "https://devapi.getkexy.com:3002",
  mockSocketUri: "https://devapi.getkexy.com:4002",
  ZONE_ID: 536833,
  GLEAP_KEY: "rEVzmGo71SZyqgf0tYkYa6lOf3YjnzKy",
  RECOMMENDED_DEAL_ZONE_ID: 536834,
  zoneOne: 536833,
  zoneTwo: 536834,
  FIFTY_PERCENT_COUPON_CODE: "FRIENDS50",
  KEXY_MASTER_PASSWORD: "kEXyMaster1287",
  AMATEUR_COUPONS: ["FLEXMEKEXY24", "FLEX99KXY", "199KEXY24", "AMA99TEST"],
  PRO_COUPONS: ["299KEXY24", "PRO99TEST"],
  LIFETIME_COUPONS: ["FLEXLIFE99", "LIFETIMEMEKEXY24"],
  ADDITIONAL_CREDITS_COUPONS: ["KXY502024", "KXY70CREDITS", "80KXY2024"],
  dripCampaignCreateTutorialUrl: "https://vimeo.com/937954323/803f7f4860?share=copy",
  brandSignupWelcomePageVideoUrl: "https://www.youtube.com/watch?v=Ual9aw-cct0",
  newEmailGenerateApiUrl: "https://cwa4r27oihgi4oxwlk2mpevqs40jehkg.lambda-url.us-east-1.on.aws/",
  oldConversationEmailGenerateApiUrl: "https://63wi6d3pqcqjidrckrre7x4zle0enabe.lambda-url.us-east-1.on.aws//",
  dripBulkEmailApiUrl: "https://g4xnobjntetxsuacq2js3looyu0kpizd.lambda-url.us-east-1.on.aws/",
  dripFollowUpEmailApiUrl: "https://jcgzgysomj4xtv5moppaj6762q0upzdk.lambda-url.us-east-1.on.aws/",
};
