import { Routes } from "@angular/router";

import { routeConstants } from "../helpers/routeConstants";
import { AuthGuard } from "../guard/auth.guard";

export const brandRoutes: Routes = [
  {
    path: routeConstants.BRAND.CREATE,
    loadChildren: () => import("../pages/brand/brand-create/brand-create.module").then((m) => m.BrandCreateModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.ORGANIZATION_LIST,
    loadChildren: () => import("../pages/brand/brand-list/brand-list.module").then((m) => m.BrandListModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.INVITATION,
    loadChildren: () =>
      import("../pages/brand/brand-invitations/brand-invitations.module").then((m) => m.BrandInvitationsModule),
  },
  {
    path: routeConstants.BRAND.INVITATION_LIST,
    loadChildren: () =>
      import("../pages/brand/brand-invitation-list/brand-invitation-list.module").then(
        (m) => m.BrandInvitationListModule
      ),
  },
  {
    path: routeConstants.BRAND.DISTRIBUTORS,
    loadChildren: () => import("../pages/brand/distributors/distributors.module").then((m) => m.DistributorsModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.WELCOME,
    loadChildren: () => import("../pages/brand/brand-welcome/brand-welcome.module").then((m) => m.BrandWelcomeModule),
  },
  {
    path: routeConstants.BRAND.EDIT_PROFILE,
    loadChildren: () =>
      import("../pages/brand/edit-brand-user/edit-brand-user.module").then((m) => m.EditBrandUserModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.LIST_INVOICE,
    loadChildren: () =>
      import("../pages/brand/brand-invoice-list/brand-invoice-list.module").then((m) => m.BrandInvoiceListModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.EDIT_COMPANY,
    loadChildren: () => import("../pages/brand/edit-brand/edit-brand.module").then((m) => m.EditBrandModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.CREDITS,
    loadChildren: () =>
      import("../pages/brand/brand-subscription/brand-subscription.module").then((m) => m.BrandSubscriptionModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.PRICE,
    loadChildren: () =>
      import("../pages/brand/brand-price/brand-price.module").then((m) => m.BrandPriceModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.SUBSCRIPTION,
    loadChildren: () =>
      import("../pages/brand/brand-credits/brand-credits.module").then((m) => m.BrandCreditsModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.SUBSCRIPTION_SELECTION,
    loadChildren: () =>
      import("../pages/brand/brand-subscription-selection/brand-subscription-selection.module").then(
        (m) => m.BrandSubscriptionSelectionModule
      ),
  },
  {
    path: routeConstants.BRAND.MARKETING_TOOLS,
    loadChildren: () =>
      import("../pages/brand/brand-marketing-tools/brand-marketing-tools.module").then(
        (m) => m.BrandMarketingToolsModule
      ),
    canActivate: [AuthGuard],
  },
  // { path: routeConstants.BRAND.INVITE_PEOPLE, component: BrandInvitePeopleComponent, canActivate: [AuthGuard] },
  {
    path: routeConstants.BRAND.INVITE_PEOPLE,
    loadChildren: () =>
      import("../pages/brand/brand-invite-people/brand-invite-people.module").then((m) => m.BrandInvitePeopleModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.SUPPRESSION_LIST,
    loadChildren: () =>
      import("../pages/brand/suppression-list/suppression-list.module").then((m) => m.SuppressionListModule),
    canActivate: [AuthGuard],
  },
  // { path: routeConstants.BRAND.ANALYTICS, component: BrandAnalyticsComponent },
  {
    path: routeConstants.BRAND.CAMPAIGN_ANALYTICS,
    loadChildren: () =>
      import("../pages/brand/campaign-analytics/campaign-analytics.module").then((m) => m.CampaignAnalyticsModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.ORGANIZATION,
    loadChildren: () => import("../pages/brand/brands/brands.module").then((m) => m.BrandsModule),
  },
  {
    path: routeConstants.BRAND.ADD_BUSINESS,
    loadChildren: () => import("../pages/brand/add-brand/add-brand.module").then((m) => m.AddBrandModule),
  },
  {
    path: routeConstants.BRAND.PROSPECTING_CONV_ALL,
    loadChildren: () =>
      import("../pages/brand/brand-conversations/brand-conversations.module").then((m) => m.BrandConversationsModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.PROSPECTING_SELECT_CONTACT,
    loadChildren: () =>
      import("../pages/brand/prospecting-select-contact/prospecting-select-contact.module").then((m) => m.ProspectingSelectContactModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.PROSPECTING_CONV_EMAIL,
    loadChildren: () =>
      import("../pages/brand/brand-prospecting/brand-prospecting.module").then((m) => m.BrandProspectingModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.PROMOTION_COMPANY_DESC,
    loadChildren: () =>
      import("../pages/brand/prospecting-company-description/prospecting-company-description.module").then(
        (m) => m.prospectingCompanyDescriptionModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.PROMOTION_PRODUCT_DESC,
    loadChildren: () =>
      import("../pages/brand/prospecting-product-description/prospecting-product-description.module").then(
        (m) => m.prospectingProductDescriptionModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.PROSPECTING_STATISTICS,
    loadChildren: () =>
      import("../pages/brand/prospecting-statistics/prospecting-statistics.module").then(
        (m) => m.prospectingStatisticsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.PROSPECTING_SALES_LEAD,
    loadChildren: () =>
      import("../pages/brand/prospecting-sales-lead/prospecting-sales-lead.module").then(
        (m) => m.prospectingSalesLeadModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.PROMOTIONS,
    loadChildren: () =>
      import("../pages/brand/brand-create-campaign/brand-create-campaign.module").then(
        (m) => m.BrandCreateCampaignModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.LIST_PROMOTION,
    loadChildren: () => import("../pages/brand/brand-campaigns/brand-campaigns.module").then((m) => m.BrandCampaignsModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.CREATE_DRIP_CAMPAIGN,
    loadChildren: () =>
      import("../pages/brand/brand-drip-campaign/brand-drip-campaign.module").then(
        (m) => m.BrandDripCampaignModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.LIST_DRIP_CAMPAIGN,
    loadChildren: () =>
      import("../pages/brand/brand-list-of-drip-campaigns/brand-list-of-drip-campaigns.module").then(
        (m) => m.BrandListOfDripCampaignsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.LAUNCH_DRIP_CAMPAIGN,
    loadChildren: () =>
      import("../pages/brand/brand-launch-drip-campaign/brand-launch-drip-campaign.module").then(
        (m) => m.BrandLaunchDripCampaignModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.MANAGE_CONTACTS,
    loadChildren: () =>
      import("../pages/brand/brand-contacts/brand-contacts.module").then(
        (m) => m.BrandContactsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.FIND_LEADS,
    loadChildren: () =>
      import("../pages/brand/brand-find-leads/brand-find-leads.module").then(
        (m) => m.BrandFindLeadsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.MANAGE_LIST,
    loadChildren: () =>
      import("../pages/brand/manage-list/manage-list.module").then(
        (m) => m.ManageListModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.LIST_CONTACTS,
    loadChildren: () =>
      import("../pages/brand/brand-list-contacts/brand-list-contacts.module").then(
        (m) => m.BrandListContactsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.BRAND.SLACK_INTEGRATION,
    loadChildren: () =>
      import("../pages/brand/slack-integration/slack-integration.module").then(
        (m) => m.SlackIntegrationModule
      ),
    canActivate: [AuthGuard],
  },
];
