const RESTAURANT = "restaurant/";
const DISTRIBUTOR = "distributor/";
const BRAND = "brand/";
const AFFILIATE = "affiliate/";
const PRESALE = "beta/";
const SUBSCRPTIONS = "subscriptions/";
export const PROSPECTING = "prospecting/";
export const PROMOTION = "promotion/";
export const DRIP_CAMPAIGN = "drip-campaign/";
export const DASHBOARD = "dashboard/";
export const SETTINGS = "settings/";
export const CONTACTS = "contacts/";
export const COMPANY_DETAILS = "company-details/";
export const routeConstants = {
  BASE_URL: "/",
  HOME: "home",
  LOGIN: "login",
  REGISTER: "register",
  FORGET_PASSWORD: "forget-password",
  RESET_PASSWORD: "reset-password",
  EMAIL_CONFIRMATION: "email-confirmation",
  EMAIL_VERIFICATION: "email-verification",
  JOIN: "join",
  SSO: "sso",
  MESSAGE_REPLY: "message-reply",
  AFFILIATE: {
    PROGRESS: AFFILIATE + "progress",
    SIGNUP: AFFILIATE + "signup",
    THANK_YOU: AFFILIATE + "thank-you",
  },
  RESTAURANT: {
    CREATE_NEW_INVENTORY: RESTAURANT + DASHBOARD + "create-new-inventory",
    BUSINESS_OPERATIONS: RESTAURANT + DASHBOARD + "business-operations",
    MENU_PERFORMANCE: RESTAURANT + DASHBOARD + "menu-performance",
    ORDERS: RESTAURANT + "orders",
    PURCHASE_ORDES: RESTAURANT + "purchase-orders",
    REPORTS: RESTAURANT + "reports",
    EDIT_PROFILE: RESTAURANT + "edit-profile",
    EDIT_COMPANY: RESTAURANT + "edit-restaurant",
    INVENTORY: RESTAURANT + "inventory",
    VIEW_INVENTORY: RESTAURANT + "view-inventory",
    SUBSCRIPTION: RESTAURANT + "subscription",
    INVITATION: RESTAURANT + "invitation",
    INVITATION_LIST: RESTAURANT + "invitation-list",
    MARKETPLACE_TYPE: RESTAURANT + "marketplace-type",
    CREATE: RESTAURANT + "create",
    LIST_PROMOTIONS: RESTAURANT + "list-of-promotions",
    FAV_DEALS: RESTAURANT + "favorite-deals",
    DEAL_INVOICE: RESTAURANT + "deal-invoices",
    DEAL_DETAILS: RESTAURANT + "deal-details",
    PROMOTION_NO_AUTH: "promotion",
    DEAL_CHECKOUT: RESTAURANT + "deals-checkout",
    MOBILE_DESIGN: RESTAURANT + "mobile-tablet-design",
    WELCOME: RESTAURANT + "welcome",
    ORGANIZATION_LIST: RESTAURANT + "restaurant-list",
    ORGANIZATION: RESTAURANT + SETTINGS + "organization",
    ADD_BUSINESS: RESTAURANT + SETTINGS + "add-restaurant",
    INVITE_PEOPLE: RESTAURANT + SETTINGS + "invite-people",
    LOCATION: RESTAURANT + SETTINGS + "location",
    RESTAURANT_FEATURE_SELECT: RESTAURANT + "features",
    RESTAURANT_SUBSCRIPTION_SELECTION: RESTAURANT + "subscription-selection",
    IMPORT_DATA: RESTAURANT + "import-data",
    UPLOAD_FILE: RESTAURANT + "upload-file",
    SELECT_KPI: RESTAURANT + "select-kpi",
    ORDER_DETAILS: RESTAURANT + "order-details",
    INVENTORY_DETAILS: RESTAURANT + "inventory-details",
  },
  DISTRIBUTOR: {
    CREATE: DISTRIBUTOR + "create",
    ORGANIZATION: DISTRIBUTOR + "organization",
    ORGANIZATION_LIST: DISTRIBUTOR + "distributor-list",
    CREATE_PROMOTION: DISTRIBUTOR + "create-promotion",
    INVITATION: DISTRIBUTOR + "invitation",
    INVITATION_LIST: DISTRIBUTOR + "invitation-list",
    INVITE_PEOPLE: RESTAURANT + "invite-people",
    SUBSCRIPTION_SELECTION: DISTRIBUTOR + "subscription-selection",
    WELCOME: DISTRIBUTOR + "welcome",
    LIST_PROMOTIONS: DISTRIBUTOR + "list-of-promotions",
    LIST_INVOICE: DISTRIBUTOR + "list-of-invoices",
    EDIT_COMPANY: DISTRIBUTOR + "edit-distributor",
    EDIT_PROFILE: DISTRIBUTOR + "edit-profile",
    SUBSCRIPTION: DISTRIBUTOR + "subscriptions",
    MARKETING_TOOLS: DISTRIBUTOR + "marketing-tools",
    ANALYTICS: DISTRIBUTOR + "analytics",
    ADD_BUSINESS: DISTRIBUTOR + "add-distributor",
  },
  BRAND: {
    PROSPECT_URL: BRAND + PROSPECTING,
    CREATE: BRAND + "create",
    PROMOTIONS: BRAND + PROMOTION + "create",
    LIST_PROMOTION: BRAND + PROMOTION + "list",
    PROMOTION_COMPANY_DESC: BRAND + COMPANY_DETAILS + "company-description",
    PROMOTION_PRODUCT_DESC: BRAND + COMPANY_DETAILS + "product-description",
    CAMPAIGN_ANALYTICS: BRAND + PROMOTION + "analytics",
    ORGANIZATION: BRAND + "organization",
    ORGANIZATION_LIST: BRAND + "brand-list",
    INVITATION: BRAND + "invitation",
    INVITATION_LIST: BRAND + "invitation-list",
    LIST_INVOICE: BRAND + "list-of-invoices",
    EDIT_COMPANY: BRAND + "edit-brand",
    EDIT_PROFILE: BRAND + "edit-profile",
    SUBSCRIPTION: BRAND + "subscriptions",
    SUBSCRIPTION_SELECTION: BRAND + "subscription-selection",
    MARKETING_TOOLS: BRAND + "marketing-tools",
    ADD_BUSINESS: BRAND + "add-brand",
    WELCOME: BRAND + "welcome",
    INVITE_PEOPLE: BRAND + "invite-people",
    DISTRIBUTORS: BRAND + "distributors",
    PROSPECTING_CONV_ALL: BRAND + PROSPECTING + "conversations",
    PROSPECTING_CONV_EMAIL: BRAND + PROSPECTING + "conversations/new-email",
    PROSPECTING_STATISTICS: BRAND + PROSPECTING + "statistics",
    PROSPECTING_SALES_LEAD: BRAND + PROSPECTING + "sales-lead",
    PROSPECTING_SELECT_CONTACT: BRAND + PROSPECTING + "select-contact",
    CREATE_DRIP_CAMPAIGN: BRAND + DRIP_CAMPAIGN + "create",
    LIST_DRIP_CAMPAIGN: BRAND + DRIP_CAMPAIGN + "list",
    LAUNCH_DRIP_CAMPAIGN: BRAND + DRIP_CAMPAIGN + "launch",
    PRICE: BRAND + SUBSCRPTIONS + "price",
    CREDITS: BRAND + "credits",
    SUPPRESSION_LIST: BRAND + "suppression-list",
    MANAGE_CONTACTS: BRAND + CONTACTS + "manage",
    FIND_LEADS: BRAND + CONTACTS + "find-leads",
    MANAGE_LIST: BRAND + CONTACTS + "manage-list",
    LIST_CONTACTS: BRAND + CONTACTS + "list-contacts",
    SLACK_INTEGRATION: BRAND + "slack-integration"
  },
  PRESALE: {
    SIGNUP: PRESALE + "signup",
    PROGRESS: PRESALE + "progress",
    THANK_YOU: PRESALE + "thank-you",
    LOGIN: PRESALE + "login",
  },
  PUBLIC_PROMOTION_ATTRIBUTES: "promotion-attributes"
};
